import { useRouter } from "next/router";
import React, { createContext, useEffect, useState } from "react";
import { TimerModal } from "../components/Modals";

export const TimerContext = createContext();

export const TimerProvider = ({ children }) => {
  const { pathname, push } = useRouter();
  const tenMinutes = 10 * 60;
  const [timer, setTimer] = useState(tenMinutes);
  const [openModal, setOpenModal] = useState(false);
  const [isFirstRender, setIsFirstRender] = useState(true);

  useEffect(() => {
    if (!isFirstRender) return;
    setTimer(localStorage.getItem("timer") || tenMinutes);
    setIsFirstRender(false);
  }, [isFirstRender]);

  useEffect(() => {
    if (isFirstRender) return;

    const isCrossBorder = String(pathname).includes("cross-border");
    const runResetPathnames = [
      "/cross-border",
      "/cross-border/batch",
      "/cross-border/batch/upload-csv",
    ];
    const runResetDefault = runResetPathnames.includes(pathname);

    const resetTimer = !isCrossBorder || runResetDefault;

    if (resetTimer) return setTimer(tenMinutes);

    if (!timer) return;

    let interval = setInterval(async () => {
      setTimer((prevTime) => {
        if (prevTime < 1) return prevTime;
        const result = prevTime - 1;
        localStorage.setItem("timer", result);
        return result;
      });
    }, 1000);

    return () => clearInterval(interval);
  }, [pathname, isFirstRender]);

  useEffect(() => {
    if (timer) return;
    setOpenModal(true);
    push("/cross-border");
  }, [timer]);

  return (
    <TimerContext.Provider value={{}}>
      {children}
      <TimerModal isOpen={openModal} onCloseModal={() => setOpenModal(false)} />
    </TimerContext.Provider>
  );
};
